
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { CompaniesService } from 'src/api/services/CompaniesService';
import { DeclineAttempt } from 'src/models/declineAttempt';
import NewDeclineAttemptIcon from '@mui/icons-material/AddCircle';
import { Company, CompanyCheckoutSettings } from 'src/models/company';
import { ApiException } from 'src/models/apiError';
import { DeclineCode } from 'src/models/declineCode';
import TemplateEditingComponent from 'src/components/TemplateEditingComponent';
import SettingsEditingComponent from 'src/components/SettingsEditingComponent';

interface CompanyDialogProps {
    isOpen: boolean
    existingCompany?: Company
    onClose: (shouldRefresh: boolean) => void
}

const CompanyDialog: FC<CompanyDialogProps> = ({ isOpen = false, existingCompany, onClose }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [settings, setSettings] = useState<any>()

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingCompany) {
            setName(existingCompany.name)
            setSettings(existingCompany.settings)
        } else {
            setName('')
            setSettings(undefined)
        }
        setError('')
    }, [existingCompany])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try{
            await CompaniesService.delete({
                id: existingCompany.id,
            })
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try{
            if (existingCompany) {
                //Edit
                await CompaniesService.edit({
                    id: existingCompany.id,
                    name,
                    settings,
                })
            } else {
                //Create
                await CompaniesService.create({
                    name,
                    settings,
               })
            }
            onClose(true)
        }catch(e) {
            if(e instanceof ApiException){
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>{existingCompany ? 'Edit Company' : 'Create Company'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1 }} mb={1}>
                    <Grid item xs={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <SettingsEditingComponent 
                        settings={settings}
                        setSettings={setSettings}
                        />
                </Grid>
                { error && <Alert severity="error">
                    {error}
                </Alert> }
            </DialogContent>
            <Divider />
            <DialogActions>
                { existingCompany && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton> }
                <Box sx={{flex: '1 0 0'}} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingCompany ? 'Save' : 'Create Company'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default CompanyDialog;

