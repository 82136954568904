import { Box, Button, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import {ChangeEvent, FC, useState} from 'react'
import { ChromePicker } from 'react-color'
import ColorPicker from './ColorPicker';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircle';
import ReactQuill from 'react-quill';
import { TemplateContentItem } from 'src/models/template';
import { RemoveCircle } from '@mui/icons-material';

interface SettingsEditingComponentProps {
    settings:any,
    setSettings: React.Dispatch<any>
}

const SettingsEditingComponent: FC<SettingsEditingComponentProps> = ({
    settings,
    setSettings,
}) => {
    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, moduleIndex:number, itemIndex:number) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;
          
                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
          
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
          
                canvas.toBlob(async (blob) => {
                  const fileType = file.type.split("/")[1];
                  let newFile:File = null;
          
                  if (fileType === "jpeg" || fileType === "jpg") {
                    newFile = new File([blob], file.name, { type: "image/jpeg" });
                  } else if (fileType === "png") {
                    newFile = new File([blob], file.name, { type: "image/png" });
                  } else if (fileType === "gif") {
                    newFile = new File([blob], file.name, { type: "image/gif" });
                  } else {
                    return
                  }
          
                    let newSettings = [...settings]
                    newSettings[moduleIndex].settings[itemIndex].value_file = await fileToBase64(newFile)
                    setSettings(newSettings)
                }, file.type, 0.8);
            };
            if(typeof e.target.result === 'string'){
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file:File):Promise<string|ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = ev => {
            resolve(ev.target.result)
          }
          reader.readAsDataURL(file)
        })
    }

    return <>{ settings && settings.map((module, moduleIndex) => {
    return <Grid container spacing={1} columns={{ xs: 1 }} key={module.key}>
        <Grid item xs={1}>
        <CardHeader title={module.name} />
        <Divider />
        <CardContent>
        <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
        {module.settings && module.settings.map((item, itemIndex) => {
        switch(item.type){
            case 'image': {
                const apiBaseURL = new URL(window.config.API_BASE_URL);
                // This checks if the pathname is exactly '/api' or '/api/' and then replaces it with '/images'
                if (apiBaseURL.pathname === '/api' || apiBaseURL.pathname === '/api/') {
                  apiBaseURL.pathname = '/images/';
                }
                const imageBasePath = apiBaseURL.href

                return <Grid item xs={1} key={item.key}>
                    <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                        <Grid item xs={1}>
                            <Button component="label" endIcon={<UploadFileIcon />}>
                                {item.label}
                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, moduleIndex, itemIndex)} />
                            </Button>
                        </Grid>
                        <Grid item xs={1}>
                            <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                <Typography variant={'caption'}>{item.caption}</Typography>
                                {item.value && item[`value_file`] === undefined && <Box component="img" src={`${imageBasePath}${item.value}`} sx={{
                                    width: 'auto',
                                    maxHeight: '50px'
                                }} />}
                                {item[`value_file`] && <Box component="img" src={item[`value_file`]} sx={{
                                    width: 'auto',
                                    maxHeight: '50px'
                                }} />}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            }    
            case 'encrypted':
            case 'text':{
                return <Grid item xs={1} key={item.key}>
                    <TextField
                    label={item.label}
                    value={item.value}
                    placeholder={item.placeholder}
                    onChange={(e) => {
                        let newSettings = [...settings]
                        newSettings[moduleIndex].settings[itemIndex].value = e.target.value
                        setSettings(newSettings)
                    }}
                    fullWidth
                /></Grid>
            }
            case 'checkbox':{
                return <Grid item xs={2} key={item.key}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox 
                        checked={item.value == true}
                        onChange={(e) => {
                            let newSettings = [...settings]
                            newSettings[moduleIndex].settings[itemIndex].value = e.target.checked
                            setSettings(newSettings)
                        }} />} label={item.label} />
                    </FormGroup>
                </Grid>
            }
        }
        })}
        </Grid> 
        </CardContent>
        </Grid> 
    </Grid>
    })}
    </>
}

export default SettingsEditingComponent