import { CheckoutPage } from "src/models/checkoutPage";
import Api from "..";
import { PageProduct } from "src/models/pageProduct";
import { PaginatedResponse } from "src/models/paginatedResponse";
import { PaginatedState } from "src/models/paginatedState";

export class CheckoutPagesService {
  static async getAll():Promise<CheckoutPage[]> {
    try {
      const { data } = await Api.client.get<CheckoutPage[]>(`checkout-pages`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async search({
    name = '',
    corporation_name = '',
  }, paginatedState: PaginatedState = { per_page: 10, page: 0 }):Promise<PaginatedResponse<CheckoutPage>> {
    var params = {
      name,
      corporation_name,
    }

    const queryString = Object.entries(paginatedState)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

    const baseUrl = 'checkout-pages/search';
    const urlWithQuery = queryString ? `${baseUrl}?${queryString}` : baseUrl;

    try {
      const { data } = await Api.client.post<PaginatedResponse<CheckoutPage>>(urlWithQuery, params);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async create(page:Partial<CheckoutPage>, templateContent:any):Promise<CheckoutPage> {
    try {
      const { data } = await Api.client.post('checkout-pages', {
        ...page,
        template_content: templateContent,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async edit(page:Partial<CheckoutPage>, templateContent:any):Promise<CheckoutPage> {
    try {
      const { data } = await Api.client.post('checkout-pages/edit', {
        ...page,
        template_content: templateContent,
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async delete(page:Partial<CheckoutPage>):Promise<void> {
    try {
      await Api.client.delete(`checkout-pages/${page.id}`);
    } catch (e) {
      throw e;
    }
  }

  static async refresh(page:Partial<CheckoutPage>):Promise<CheckoutPage> {
    try {
      const { data } = await Api.client.get(`checkout-pages/${page.id}/refresh`);
      return data.page
    } catch (e) {
      throw e;
    }
  }

  static async deactivate(pageID:number):Promise<CheckoutPage> {
    try {
      const { data } = await Api.client.post(`checkout-pages/${pageID}/deactivate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

  static async activate(pageID:number):Promise<CheckoutPage> {
    try {
      const { data } = await Api.client.post(`checkout-pages/${pageID}/activate`);
      return data;
    } catch (e) {
      throw e;
    }
  }

}