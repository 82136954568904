
import { FC, ChangeEvent, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Alert, Autocomplete, Box, Checkbox, Chip, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckoutPagesService } from 'src/api/services/CheckoutPagesService';
import AddIcon from '@mui/icons-material/AddCircle';
import { CheckoutPage } from 'src/models/checkoutPage';
import { ApiException } from 'src/models/apiError';
import { Domain } from 'src/models/domain';
import { Template, TemplatePath, TemplateThumbnail } from 'src/models/template';
import { RemoveCircle } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ColorPicker from 'src/components/ColorPicker';
import { Company } from 'src/models/company';

interface CheckoutPageDialogProps {
    isOpen: boolean
    existingPage?: CheckoutPage
    onClose: (shouldRefresh: boolean) => void
    companies: Company[]
    domains: Domain[]
    templates: Template[]
}

const PageDialog: FC<CheckoutPageDialogProps> = ({
    isOpen = false,
    existingPage,
    onClose,
    companies,
    domains,
    templates,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string>('')

    const [name, setName] = useState<string>('')
    const [path, setPath] = useState<string>('')
    const [companyID, setCompanyID] = useState<string>('')
    const [company, setCompany] = useState<Company>()
    const [domainID, setDomainID] = useState<string>('')
    const [domain, setDomain] = useState<Domain>()
    const [templateID, setTemplateID] = useState<string>('')
    const [template, setTemplate] = useState<Template>()
    const [templateContent, setTemplateContent] = useState<any>()
        

    const handleClose = () => {
        onClose(false)
    };

    useEffect(() => {
        if (existingPage) {
            setName(existingPage.name)
            setPath(existingPage.path)
            setDomainID(existingPage.domain_id)
            setTemplateID(existingPage.template_id)
            if (existingPage.template_content) {
                setTemplateContent(existingPage.template_content)
            } else if (existingPage.template) {
                setTemplateContent(existingPage.template.content)
            }
            const company = companies.find(c => `${c.id}` == existingPage.company_id)
            setCompany(company)
            const domain = domains.find(d => `${d.id}` == existingPage.domain_id)
            setDomain(domain)
            const template = templates.find(t => `${t.id}` == existingPage.template_id)
            setTemplate(template)
        } else {
            setName('')
            setPath('')
            setCompanyID('')
            setDomainID('')
            setTemplateID('')
            setTemplateContent(undefined)
            setDomain(undefined)
            setTemplate(undefined)
        }
        setError('')
    }, [existingPage])

    const handleOnDelete = async () => {
        setIsLoading(true)
        try {
            await CheckoutPagesService.delete({
                id: existingPage.id,
            })
            onClose(true)
        } catch (e) {
            if (e instanceof ApiException) {
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleOnSave = async () => {
        setIsLoading(true)
        setError('')
        try {
            if (existingPage) {
                //Edit
                await CheckoutPagesService.edit({
                    id: existingPage.id,
                    name,
                    path,
                    company_id: companyID,
                    domain_id: domainID,
                    template_id: templateID,
                }, templateContent)
            } else {
                //Create
                await CheckoutPagesService.create({
                    name,
                    path,
                    company_id: companyID,
                    domain_id: domainID,
                    template_id: templateID,
                }, templateContent)
            }
            onClose(true)
        } catch (e) {
            if (e instanceof ApiException) {
                setError(e.toString())
            }
        }
        setIsLoading(false)
    }

    const handleCompanyChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setCompanyID(value)
        const company = companies.find(c => `${c.id}` == value)
        setCompany(company)
    }

    const handleDomainChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setDomainID(value)
        const domain = domains.find(d => `${d.id}` == value)
        setDomain(domain)
    }

    const handleTemplateChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        setTemplateID(value)
        const template = templates.find(t => `${t.id}` == value)
        setTemplate(template)
        if (template) {
            setTemplateContent({ ...template.content })
        }
    }

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>, key: string, index?: number, child_key?: string) => {
        const { target } = event
        console.log(target)
        //const template = templates.find(t => `${t.id}` == value)
        const file = target.files[0];
        const fileReader = new FileReader();
        const name = target.accept.includes('image') ? 'images' : 'videos';

        fileReader.onload = (e) => {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                const MAX_WIDTH = 800;
                const MAX_HEIGHT = 800;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(async (blob) => {
                    const fileType = file.type.split("/")[1];
                    let newFile: File = null;

                    if (fileType === "jpeg" || fileType === "jpg") {
                        newFile = new File([blob], file.name, { type: "image/jpeg" });
                    } else if (fileType === "png") {
                        newFile = new File([blob], file.name, { type: "image/png" });
                    } else if (fileType === "gif") {
                        newFile = new File([blob], file.name, { type: "image/gif" });
                    } else {
                        return
                    }

                    let content = { ...templateContent }
                    if (index !== undefined && child_key !== undefined) {
                        //it's a child inside of an array
                        content[key][index][`${child_key}_file`] = await fileToBase64(newFile)
                    } else {
                        if (key.startsWith('variant')) {
                            const variantIndex = key.split('_')[1]
                            content['product_variants'][variantIndex]['file'] = await fileToBase64(newFile)
                        } else {
                            content[`${key}_file`] = await fileToBase64(newFile)
                        }
                    }
                    setTemplateContent(content)
                }, file.type, 0.8);
            };
            if (typeof e.target.result === 'string') {
                img.src = e.target.result;
            }
        }
        fileReader.readAsDataURL(file);
    }

    async function fileToBase64(file: File): Promise<string | ArrayBuffer> {
        const reader = new FileReader()
        return new Promise(resolve => {
            reader.onload = ev => {
                resolve(ev.target.result)
            }
            reader.readAsDataURL(file)
        })
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
            <DialogTitle>{existingPage ? 'Edit Checkout Page' : 'Create Checkout Page'}</DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={1} columns={{ xs: 1, sm: 4 }} mb={2}>
                    <Grid item xs={1} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>Company</InputLabel>
                            <Select
                                value={companyID}
                                label="Company"
                                onChange={handleCompanyChange}
                            >
                                {companies.map((company) =>
                                    <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <TextField
                            autoFocus
                            label="Name"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1} sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Domain</InputLabel>
                            <Select
                                value={domainID}
                                label="Domain"
                                onChange={handleDomainChange}
                            >
                                {domains.map((domain) =>
                                    <MenuItem key={domain.id} value={domain.id}>{domain.host}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            InputProps={{
                                startAdornment: <InputAdornment position="start">/</InputAdornment>,
                            }}
                            label="Path"
                            value={path}
                            onChange={(e) => {
                                setPath(e.target.value)
                            }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={1} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>Template</InputLabel>
                            <Select
                                value={templateID}
                                label="Template"
                                onChange={handleTemplateChange}
                                SelectDisplayProps={{
                                    style: {display: 'flex', alignItems: 'center'},
                                }}
                            >
                                {templates.map((template) =>
                                    <MenuItem key={template.id} value={template.id}>
                                        {template.image && <ListItemIcon sx={{minWidth: 56}}>
                                            <img height={32} src={`${TemplateThumbnail(template)}`} />
                                        </ListItemIcon>}
                                        <ListItemText primary={template.name} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                {template && <Grid container spacing={1} columns={{ xs: 2 }} mt={1} mb={2}>
                    {template.content.map((item) => {
                        const key = item.key
                        let templateContentValue = ''
                        if (templateContent) {
                            templateContentValue = templateContent[key] ?? ''
                        }
                        switch (item.type) {
                            case 'color': {
                                return <Grid item xs={1} key={key}>
                                    <ColorPicker defaultColor={templateContentValue} label={item.label}                                         onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e
                                            setTemplateContent(newContent)
                                        }} />
                                </Grid>
                            }
                            case 'image': {
                                return <Grid item xs={1} key={key}>
                                    <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                                        <Grid item xs={1}>
                                            <Button component="label" endIcon={<UploadFileIcon />}>
                                                {item.label}
                                                <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, key)} />
                                            </Button>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box sx={{ flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                                                <Typography variant={'caption'}>{item.caption}</Typography>
                                                {templateContent[key] && templateContent[`${key}_file`] === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${templateContent[key]}`} sx={{
                                                    width: 'auto',
                                                    maxHeight: '50px'
                                                }} />}
                                                {templateContent[`${key}_file`] && <Box component="img" src={templateContent[`${key}_file`]} sx={{
                                                    width: 'auto',
                                                    maxHeight: '50px'
                                                }} />}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            case 'variants': {
                                const contentVariants = templateContent[key] ?? []
                                return <Grid item xs={2} mb={1} key={key}>
                                    <Grid item xs={2}>
                                        <Typography mb={1} >
                                            Product Variants
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {contentVariants.map((variant, i) => {
                                            return <Grid container spacing={1} columns={{ xs: 3 }} key={`variant_${i}`}>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        label={`Color`}
                                                        value={variant.color}
                                                        onChange={(e) => {
                                                            let newContent = { ...templateContent }
                                                            newContent[key][i].color = e.target.value
                                                            setTemplateContent(newContent)
                                                        }}
                                                        fullWidth
                                                    /></Grid>
                                                <Grid item xs={1}>
                                                    <TextField
                                                        label={`Hex`}
                                                        value={variant.hex}
                                                        onChange={(e) => {
                                                            let newContent = { ...templateContent }
                                                            newContent[key][i].hex = e.target.value
                                                            setTemplateContent(newContent)
                                                        }}
                                                        fullWidth
                                                    /></Grid>

                                                <Grid item xs={1}>
                                                    <Button component="label" endIcon={<UploadFileIcon />}>
                                                        <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, `variant_${i}`)} />
                                                    </Button>
                                                    {variant.image && variant.file === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${variant.image}`} sx={{
                                                        width: 'auto',
                                                        maxHeight: '50px'
                                                    }} />}
                                                    {variant.file && <Box component="img" src={`${variant.file}`} sx={{
                                                        width: 'auto',
                                                        maxHeight: '50px'
                                                    }} />}
                                                </Grid>
                                            </Grid>

                                        })}

                                        <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                            let newContent = { ...templateContent }
                                            let variants = newContent[key]
                                            if (!variants) {
                                                newContent[key] = []
                                            }

                                            newContent[key].push({
                                                color: '',
                                                hex: '',
                                                image: '',
                                            })

                                            setTemplateContent(newContent)
                                        }}>
                                            Add Variant
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            case 'children': {
                                const contentChildren = templateContent[key] ?? []
                                return <Grid item xs={2} mb={1} key={key}>
                                    <Grid item xs={2}>
                                        <Typography mb={1} >
                                            {item.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {contentChildren.map((childContent, i) => {
                                            return <Grid container spacing={1} columns={{ xs: 2 }} key={`children_${i}`}>
                                                {item.children != undefined && Object.keys(item.children).map((childKey, j) => {
                                                    const child = item.children[childKey]
                                                    switch (child.type) {
                                                        case 'text': {
                                                            return <Grid item xs={1} key={`child_${child.key}_${i}`}>
                                                                <TextField
                                                                    label={child.label}
                                                                    value={childContent[child.key]}
                                                                    placeholder={child.placeholder}
                                                                    onChange={(e) => {
                                                                        let newContent = { ...templateContent }
                                                                        newContent[key][i][child.key] = e.target.value
                                                                        setTemplateContent(newContent)
                                                                    }}
                                                                    fullWidth
                                                                /></Grid>
                                                        }
                                                        case 'image': {
                                                            return <Grid item xs={1} key={`child_${child.key}_${i}`}>
                                                                <Grid container spacing={1} columns={{ xs: 1, sm: 2 }}>
                                                                    <Grid item xs={1}>
                                                                        <Button component="label" endIcon={<UploadFileIcon />}>
                                                                            {child.label}
                                                                            <input hidden accept="image/*" multiple type="file" onChange={(event) => handleImageChange(event, key, i, child.key)} />
                                                                        </Button>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                                                            <Typography variant={'caption'}>{child.caption}</Typography>
                                                                            {childContent[child.key] && childContent[`${child.key}_file`] === undefined && <Box component="img" src={`${location.protocol}//${domain.host}/assets/${template.key}/images/${childContent[child.key]}`} sx={{
                                                                                width: 'auto',
                                                                                maxHeight: '50px'
                                                                            }} />}
                                                                            {childContent[`${child.key}_file`] && <Box component="img" src={childContent[`${child.key}_file`]} sx={{
                                                                                width: 'auto',
                                                                                maxHeight: '50px'
                                                                            }} />}
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                    }
                                                    return <Grid item xs={1} key={`child_${j}`}>
                                                        Missing type</Grid>
                                                })}
                                                <Grid item xs={1}>
                                                    <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                                        let newContent = { ...templateContent }
                                                        let deals = newContent[key]
                                                        deals.splice(i, 1)
                                                        setTemplateContent(newContent)
                                                    }}>
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        })}

                                        <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                            let newContent = { ...templateContent }
                                            let deals = newContent[key]
                                            if (!deals) {
                                                newContent[key] = []
                                            }

                                            const children = (item.children ?? []).map(c => {
                                                var obj = {}
                                                obj[c.key] = ''
                                                return obj
                                            })

                                            newContent[key].push(children.reduce((accumulator, current) => {
                                                return { ...accumulator, ...current };
                                            }, {}))

                                            setTemplateContent(newContent)
                                        }}>
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            case 'encrypted':
                            case 'text': {
                                return <Grid item xs={1} key={key}>
                                    <TextField
                                        label={item.label}
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e.target.value
                                            setTemplateContent(newContent)
                                        }}
                                        fullWidth
                                    /></Grid>
                            }
                            case 'textarea': {
                                return <Grid item xs={2} key={key}>
                                    <TextField
                                        multiline
                                        label={item.label}
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e.target.value
                                            setTemplateContent(newContent)
                                        }}
                                        fullWidth
                                    /></Grid>
                            }
                            case 'richtext': {
                                return <Grid item xs={2} key={key}>
                                    <Typography>{item.label}</Typography>
                                   <ReactQuill
                                        value={templateContentValue}
                                        placeholder={item.placeholder}
                                        onChange={(e) => {
                                            let newContent = { ...templateContent }
                                            newContent[key] = e
                                            setTemplateContent(newContent)
                                        }}
                                    />
                                </Grid>
                            }
                            case 'checkbox': {
                                let templateContentValue = null
                                if (templateContent) {
                                    templateContentValue = templateContent[key] ?? null
                                }

                                return <Grid item xs={2} key={key}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={templateContentValue != null}
                                            onChange={(e) => {
                                                let newContent = { ...templateContent }
                                                newContent[key] = e.target.checked ? '' : null
                                                setTemplateContent(newContent)
                                            }} />} label={item.label} />
                                    </FormGroup>
                                    {templateContentValue != null &&
                                        <TextField
                                            label={item.label}
                                            value={templateContentValue}
                                            placeholder={item.placeholder}
                                            onChange={(e) => {
                                                let newContent = { ...templateContent }
                                                newContent[key] = e.target.value
                                                setTemplateContent(newContent)
                                            }}
                                            fullWidth
                                        />}
                                </Grid>
                            }
                            case 'form': {
                                const contentForm = templateContent[key] ?? []
                                return <Grid item xs={2} mb={1} key={key}>
                                    <Grid item xs={2}>
                                        <Typography mb={1} >
                                            {item.label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                    {contentForm.map((variant, i) => {
                                    return <Grid container mb={1} spacing={1} columns={{ xs: 8 }} key={`variant_${i}`}>
                                        <Grid item xs={2}>
                                            <TextField
                                                label={`ID`}
                                                value={variant.id}
                                                onChange={(e) => {
                                                    let newContent = { ...templateContent }
                                                    newContent[key][i].id = e.target.value
                                                    setTemplateContent(newContent)
                                                }}
                                                fullWidth
                                            /></Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label={`Label`}
                                                value={variant.label}
                                                onChange={(e) => {
                                                    let newContent = { ...templateContent }
                                                    newContent[key][i].label = e.target.value
                                                    setTemplateContent(newContent)
                                                }}
                                                fullWidth
                                            /></Grid>
                                        <Grid item xs={2}>
                                            <TextField
                                                label={`Placeholder`}
                                                value={variant.placeholder}
                                                onChange={(e) => {
                                                    let newContent = { ...templateContent }
                                                    newContent[key][i].placeholder = e.target.value
                                                    setTemplateContent(newContent)
                                                }}
                                                fullWidth
                                            /></Grid>

                                        <Grid item xs={1}>
                                            <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                checked={variant.required}
                                                onChange={(e) => {
                                                    let newContent = { ...templateContent }
                                                    newContent[key][i].required = e.target.checked
                                                    setTemplateContent(newContent)
                                                }} />} label="Required"/>
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Button component="label" endIcon={<RemoveCircle />} onClick={() => {
                                                let newContent = { ...templateContent }
                                                let variants = newContent[key]
                                                variants.splice(i, 1)
                                                setTemplateContent(newContent)
                                            }}>
                                                Remove
                                            </Button>
                                        </Grid>
                                    </Grid>

                                })}

                                <Button component="label" endIcon={<AddIcon />} onClick={() => {
                                    let newContent = { ...templateContent }
                                    let variants = newContent[key]
                                    if (!variants) {
                                        newContent[key] = []
                                    }

                                    newContent[key].push({
                                        id: '',
                                        label: '',
                                        palceholder: '',
                                        required: false,
                                    })

                                    setTemplateContent(newContent)
                                }}>
                                    Add Custom Field
                                </Button>
                            </Grid>
                        </Grid>
                        }
                    }
                    })}
                </Grid>}
            </DialogContent>
            <Divider />
            {error && <>
                <Alert severity="error">
                    {error}
                </Alert>
                <Divider />
            </>}
            <DialogActions>
                {existingPage && <LoadingButton
                    color='error'
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<DeleteIcon />}
                    onClick={handleOnDelete}
                >Delete</LoadingButton>}
                <Box sx={{ flex: '1 0 0' }} />
                <LoadingButton
                    loading={isLoading}
                    loadingPosition='start'
                    startIcon={<SaveIcon />}
                    onClick={handleOnSave}
                >{existingPage ? 'Save' : 'Create Checkout Page'}</LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

export default PageDialog;

