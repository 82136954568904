import { Helmet } from 'react-helmet-async';
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaginatedState } from 'src/models/paginatedState';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import { CompaniesService } from 'src/api/services/CompaniesService';
import CompaniesTable from './CompaniesTable';
import { Company } from 'src/models/company';
import CompanyDialog from './CompanyDialog';
import { DeclineCode } from 'src/models/declineCode';
import NewCompanyIcon from '@mui/icons-material/AddCircle';
import { LoadingButton } from '@mui/lab';

function ApplicationsCompanies() {
  const [paginatedResponse, setPaginatedResponse] = useState<PaginatedResponse<Company>>()
  const [paginatedState, setPaginatedState] = useState<PaginatedState>({per_page: 10, page: 0})
  const [searchTerms, setSearchTerms] = useState<Partial<Company>>({
    name: '',
  })

  const [isSearching, setIsSearching] = useState(false)
  const [serverIP, setServerIP] = useState<string>()

  const onSearch = async (terms:Partial<Company>) => {
    const newTerms = {...terms}
    setSearchTerms(newTerms)
  }

  const onClear = async (terms:Partial<Company>) => {
    setSearchTerms(terms)
  }

  const refreshData = async () => {
    setIsSearching(true)
    try{
      const response = await CompaniesService.search({
        ...searchTerms,
      }, paginatedState)
      setPaginatedResponse(response)
    } catch(e){}
    setIsSearching(false)
  }

  useEffect(() => {
    refreshData()
  },[searchTerms, paginatedState])

  const onPageChange = (newPage: number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.page = newPage
    setPaginatedState(newPaginatedState)
  };

  const onRowsPerPageChange = (newRowsPerPage:number) => {
    let newPaginatedState = {...paginatedState}
    newPaginatedState.per_page = newRowsPerPage
    setPaginatedState(newPaginatedState)
  };

  const [isCompanyDialogOpen, setIsCompanyDialogOpen] = useState(false)
  const [existingCompany, setExistingCompany] = useState<Company>()
  const [isRefreshingCompany, setIsRefreshingCompany] = useState(false)

  const onNewCompany = () => {
    setExistingCompany(undefined)
    setIsCompanyDialogOpen(true)
  }

  const onEditCompany = (corporation:Company) => {
    setExistingCompany(corporation)
    setIsCompanyDialogOpen(true)
  }

  const onCompanyDialogClose = (shouldRefresh:boolean) => {
    setIsCompanyDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  const [isCompanyActivationDialogOpen, setIsCompanyActivationDialogOpen] = useState(false)

  const onActivateCompany = (corporation:Company) => {
    setExistingCompany(corporation)
    setIsCompanyActivationDialogOpen(true)
  }

  const onDeactivateCompany = (corporation:Company) => {
    setExistingCompany(corporation)
    setIsCompanyActivationDialogOpen(true)
  }

  const onCompanyActivationDialogClose = (shouldRefresh:boolean) => {
    setIsCompanyActivationDialogOpen(false)
    if(shouldRefresh){
      refreshData()
    }
  }

  return (
    <>
      <Helmet>
        <title>Companies</title>
      </Helmet>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={2}
          marginTop={0}
        >
          <Grid item xs={12}>
            {/*<PageHeader 
              searchTerms={searchTerms} 
              isSearching={isSearching} 
              onSearch={onSearch} 
              onClear={onClear} 
              onNewCompany={onNewCompany} 
            />*/}
            <LoadingButton
              loading={isSearching}
              loadingPosition='start'
              startIcon={<NewCompanyIcon />}
              variant='contained'
              onClick={() => {
                onNewCompany()
              }}
            >New Company</LoadingButton>
          </Grid>
          <Grid item xs={12}>
            <CompaniesTable 
              paginatedResponse={paginatedResponse} 
              paginatedState={paginatedState} 
              isRefreshing={isSearching} 
              onPageChange={onPageChange} 
              onRowsPerPageChange={onRowsPerPageChange}
              onEditCompany={onEditCompany}
              onActivateCompany={onActivateCompany}
              onDeactivateCompany={onDeactivateCompany}
            />
          </Grid>
        </Grid>
      </Container>
      <CompanyDialog 
        isOpen={isCompanyDialogOpen} 
        onClose={onCompanyDialogClose} 
        existingCompany={existingCompany} 
      />
    </>
  );
}

export default ApplicationsCompanies;
