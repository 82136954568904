import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  CardHeader,
  Link as MuiLink,
  Backdrop,
  styled,
  CircularProgress,
  Button,
  IconButton,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { PaginatedState } from 'src/models/paginatedState';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CheckoutPage } from 'src/models/checkoutPage';
import CheckedIcon from '@mui/icons-material/CheckCircleOutline';
import UncheckedIcon from '@mui/icons-material/ErrorOutline';
import { PaginatedResponse } from 'src/models/paginatedResponse';
import DashboardTableCell from 'src/components/DashboardTableCell';

interface CheckoutPagesTableProps {
  className?: string
  paginatedResponse?: PaginatedResponse<CheckoutPage>
  paginatedState: PaginatedState
  isRefreshing:boolean
  onPageChange:(page:number) => void
  onRowsPerPageChange:(rowsPerPage:number) => void
  onEditPage:(page:CheckoutPage) => void
  onDeactivatePage:(page:CheckoutPage) => void
  onActivatePage:(page:CheckoutPage) => void
}

const LimitedBackdrop = styled(Backdrop)(
  () => `
    position: absolute;
    z-index: 1;
`
);

const CheckoutPagesTable: FC<CheckoutPagesTableProps> = ({
  paginatedResponse, 
  paginatedState,
  isRefreshing = false, 
  onPageChange, 
  onRowsPerPageChange,
  onEditPage,
  onDeactivatePage,
  onActivatePage,
}) => {
  const navigate = useNavigate()

  const handlePageChange = async (event: any, newPage: number) => {
    onPageChange(newPage)
  };

  const handleLimitChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onRowsPerPageChange(parseInt(event.target.value))
  };

  return (
    <Card sx={{
      position:'relative'
    }}>
      <LimitedBackdrop open={isRefreshing}><CircularProgress size={'5rem'} /></LimitedBackdrop>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Template</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResponse?.data.map((item) => {
              return (
                <TableRow
                  key={item.id}
                  >
                    <DashboardTableCell content={item.id} />
                    <DashboardTableCell content={item.name} />
                    <DashboardTableCell content={item.template?.name ?? 'NO TEMPLATE SELECTED'} />
                    <DashboardTableCell content={<>
                      <Box display='flex'>
                    <LoadingButton
                      onClick={() => {
                        onEditPage(item)
                      }}
                    >Edit</LoadingButton>
                    <LoadingButton
                      color={ item.active ? 'error' : 'success'}
                      onClick={() => {
                        if(item.active){
                          onActivatePage(item)
                        }else{
                          onDeactivatePage(item)
                        }
                      }}
                    >{ item.active ? 'Deactivate' : 'Activate'}</LoadingButton>

                    </Box>
                    </>} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={paginatedResponse?.total ?? 0}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={paginatedState.page}
          rowsPerPage={paginatedState.per_page}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default CheckoutPagesTable;
